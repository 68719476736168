/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import {
  CentralUser,
  MachineStatus,
  TimeSeriesValueCompactValue,
  MachineStateSummary,
  SystemPermissions,
  EntityPermission,
  GUID,
  TimeSeries,
  Machine,
  ProvisionMachine,
  Licence,
  TimeSeriesLimit,
  Client,
  MachineConfiguration,
  OffsetAdjustmentApplied,
  OffsetAdjustmentCalculation,
  SortDirection,
  FeatureGroup,
  UnitDisplayHint,
  ProcessAction,
  FileInfoForSelectedJob,
  FileDetailsForSelectedJob,
  MyNotification,
  MachineType,
  IPCRunMode,
  MachineConfigurationDetailsPatch,
} from "@centralwebteam/narwhal";
import { NotificationType } from "@centralwebteam/jellyfish";
import { AlertPresentation } from "@/presentation/Alert";
import { Routes } from "./session/routeDefinitions";
import { JobPresentation } from "@/presentation/Job";
import { MeasurementCharacteristicPresentation } from "@/presentation/MeasurementCharacteristic";
import { LocationUI } from "@/presentation/Location";
import { ProcessUpdatePresentation } from "@/presentation/processUpdates";
import { ViewState } from "@centralwebteam/jellyfish/src/Login/types";

// #region app types

/** Translation keys for provisioning tab titles */
export const ProvisionTabs = {
  createNewMachine: "heading-createNewMachine",
  selectExistingMachine: "heading-selectExistingMachine",
  foundMatchedMachine: "heading-foundMatchedMachine",
};

export type MachinePerformance = {
  totalErrorAlerts: number;
  statusOk: { seconds: number; percent: number };
};
export type JobAggregate = {
  completed: number;
  incomplete: number;
  fail: number;
  passAndWarn: number;
  noVerdict: number;
  total: number;
};

export type JobStatsAggregate = {
  name: string;
  total: number;
  completed: number;
  incomplete: number;
  fail: number;
  passAndWarn: number;
  noVerdict: number;
};
export type FilterKeys =
  | "locationId"
  | "machineLicensed"
  | "machineType"
  | "machineStatus"
  | "alertLevel"
  | "name"
  | "userEmail"
  | "verdict"
  | "clientName";
export type Filter = { [key in FilterKeys]: string[] };

export const knownStartValues = [
  "1 hour",
  "1 day",
  "3 days",
  "7 days",
] as const;
export type KnownStartValues = (typeof knownStartValues)[number];

export type RefreshMode = "locked" | "refresh";

export type AlertTypeReason = {
  id: number;
  cumulativePercentage: number;
  percentage: number;
  name: string;
  total: number;
  meanTimeBeforeFail?: string;
};

export const machineAnalysisMetrologyViewTypes = [
  "Job",
  "Process",
  "Machine",
] as const;
export const machineAnalysisAdditiveViewTypes = ["Process", "Machine"] as const;
export type MachineAnalysisMetrologyViewType =
  (typeof machineAnalysisMetrologyViewTypes)[number];
export type MachineAnalysisAdditiveViewType =
  (typeof machineAnalysisAdditiveViewTypes)[number];
export const jobViewTypes = ["Single", "Multi"] as const;
export type JobViewType = (typeof jobViewTypes)[number];
export const measurementPlotTypes = ["actual", "deviation"] as const;
export type MeasurementPlotType = (typeof measurementPlotTypes)[number];
export type FocusedOrLatestJobViewMeasurementType =
  | "actual"
  | "deviation"
  | "error";
export type MeasurementTypeDetail = {
  featureName?: string;
  name: string;
  /** Although this has a plural name, it is a single FeatureGroup */
  featureGroups?: FeatureGroup;
  toleranceType: string;
  toleranceSubType: string;
  active: boolean;
  unit?: string;
  sampleRate?: string;
};

export type TimeSeriesTypeDetail = TimeSeries & {
  active: boolean;
};

/** An array of data from the API, along with the `from` and `to` params used to fetch it */
export type DataRecord<T extends any[]> = {
  data: T;
  params: {
    from: string;
    to: string;
  };
};

export type Popup = {
  group?: string;
  id: string;
  /** Measurements of the origin element for the popup [x, y, width, height] */
  coord: [number, number, number, number];
  zIndex: number;
};

export type JobSummaryView =
  | "Info"
  | "Characteristics (Fail & Warning)"
  | "Files"
  | "More";

export type PopupUpdate = {
  show?: "toggle" | boolean;
  group?: string;
  id: string;
  coord?: [number, number, number, number];
};

export const viewMode = ["NORMAL", "MICRO"] as const;
export type ViewMode = (typeof viewMode)[number];

export type CarouselDataSet = {
  sensor: TimeSeries;
  values: TimeSeriesValueCompactValue[];
  limits: TimeSeriesLimit[];
};

export type JobPerformance = {
  /** Job name */
  name: string;
  /** Median cycle time in seconds */
  cycleTime: number;
  /** Job run count by verdict group */
  count: {
    fail: number;
    warning: number;
    pass: number;
    passAndWarning: number;
    noVerdict: number;
    completed: number;
    incomplete: number;
    total: number;
  };
  /** Job cumulative duration by verdict group */
  duration: {
    fail: number;
    warning: number;
    pass: number;
    noVerdict: number;
    completed: number;
    incomplete: number;
    total: number;
  };
  /** Pass / fail split in percent. Other verdicts are not included, so the total is <= 100. */
  percent: {
    passAndWarning: number;
    fail: number;
    noVerdict: number;
    completed: number;
    incomplete: number;
  };
};

export type JobType = {
  name: string;
  /** IDs of machines running this job.
   * Set used to get unique values.
   * TODO: Revise this, because Redux warns against using Set in state. */
  machines: Set<GUID>;
  /** index of job instance */
  instances: number[];
};

export type JobPerfSortUnit = "Count" | "Percent";
export type JobPerfSortColumn = "Name" | "Passes" | "Fails" | "Jobs" | "Time";
export type JobPerfDetailSortColumnMetrology =
  | "Job Start"
  | "Total Toleranced"
  | "Features Passed"
  | "Features Failed"
  | "Job Status"
  | "Job Verdict"
  | "Cycle Time";
export type JobPerfDetailSortColumnAdditive =
  | "Job Start"
  | "Total Layers"
  | "Last Layer"
  | "Progress"
  | "Job Status"
  | "Job Verdict"
  | "Cycle Time";

export type JobPerfMachineJobAggregateSortColumn =
  | "Machine"
  | "Location"
  | "Total Jobs"
  | "Passes"
  | "Fails"
  | "Completed"
  | "Incomplete"
  | "Total Cycle Time"
  | "Median Cycle Time";

export type JobPerfState = "Fetching" | "Working" | "Idle";

export type TreeViewSelectionMode = "single" | "multi";
export type NodeType = "machine" | "location";

export type ManageUpsertMode = "modify" | "create";

export const sidebarSections = ["jobs", "timeseries", "measurements"] as const;
export type SidebarSection = (typeof sidebarSections)[number];

export type MachinePerfSortColumn =
  | "Name"
  | "Location"
  | "Completed"
  | "Incomplete"
  | "Passes"
  | "Fails"
  | "Jobs"
  | "Time"
  | "AlertErrors"
  | "AlertWarnings";

export type ToolOffsetAppliedType = {
  name: string;
  items: ProcessUpdatePresentation[];
};
export type ProcessUpdatesDetailsSortColumn =
  | "Controlled Offset"
  | "Process Information"
  | "Offset Usage"
  | "Offset Value"
  | "Last Offset Adjustment"
  | "Run Mode"
  | "Measured Feature"
  | "Measured Characteristics"
  | "Job Name"
  | "Updated";
export type ProcessUpdatesDetailsInnerTableSortColumn =
  | "Controlled Offset"
  | "Process Information"
  | "Offset Usage"
  | "Offset Value"
  | "Last Offset Adjustment"
  | "Run Mode"
  | "Measured Feature"
  | "Measured Characteristics"
  | "Job Name"
  | "Updated";
export type OffsetAdjustmentTableSortColumn =
  | "Offset Usage"
  | "Offset Adjustment"
  | "Offset Value"
  | "Feature"
  | "Run Mode"
  | "Characteristic"
  | "Job Name"
  | "Updated";

export type ProcessUpdatesPerfSortColumn =
  | "Manufacturing Machine"
  | "Exceeded"
  | "Applied"
  | "Run Mode"
  | "Passes"
  | "Fails"
  | "Job Name"
  | "Measuring Machine"
  | "Updated";
export type ProcessUpdatePerf = {
  name: string;
  targetMachine: {
    name: string;
    type: MachineType | undefined;
  };
  offsets: {
    exceeded: number;
    applied: number;
  };
  runMode: IPCRunMode | undefined;
  characteristics: {
    fails: number;
    passes: number;
  };
  jobName: string;
  measurementVerdict: string | undefined;
  sourceMachine: {
    name: string;
    type: MachineType | undefined;
  };
  time: Date | undefined;
  controlledOffset: string;
  displayName: string;
};
// #endregion

// #region partial state types
export type MachinePerformanceState = {
  machineJobAggregates: Mappy<JobAggregate>;
  machinePerformances: Mappy<MachinePerformance>;
  lastFetched: null | string;
  work: "Fetching" | "Idle";
  sortColumn: MachinePerfSortColumn;
  sortDirection: SortDirection;
  openMachines: string[];
  machineJobStats: Mappy<JobStatsAggregate[]>;
  machineStatuses: Mappy<{ name: string; percentage: number; fill: string }[]>;
  machineAlerts: Mappy<{ count: number; name: string }[]>;
};

export type ProcessUpdatesState = {
  toolOffsetApplied: (OffsetAdjustmentApplied | OffsetAdjustmentCalculation)[];
  processUpdatesPerf: MappyAbsolute<ProcessUpdatePerf>;
  processUpdateDetails: MappyAbsolute<ProcessUpdatePresentation[]>;
  processUpdateDetailsMore: MappyAbsolute<
    MappyAbsolute<ProcessUpdatePresentation[]>
  >;
  processUpdatesDetailsSortColumn: ProcessUpdatesDetailsSortColumn;
  processUpdatesDetailsSortDirection: SortDirection;
  processUpdatesPerfSortColumn: ProcessUpdatesPerfSortColumn;
  processUpdatesPerfSortDirection: SortDirection;
  detailsInnerTableSortColumn: ProcessUpdatesDetailsInnerTableSortColumn;
  detailsInnerTableSortDirection: SortDirection;
  lastFetched: null | string;
  work: "Fetching" | "Idle";
  openToolOffsets: string[];
  openProcessUpdatesDetailsList: string[];
  offsetAdjustmentSortColumn: OffsetAdjustmentTableSortColumn;
  offsetAdjustmentSortDirection: SortDirection;
};
export type FilterState = {
  draft: Filter;
  active: Filter;
};

export type GlobalState = {
  machines: Machine[];
  machineTypes: string[];
  locations: LocationUI[];
  expandedLocations: string[];
  notification: {
    show: boolean;
    body: string[];
    header: string;
    id: string;
    type: NotificationType;
  };
  dialogue: {
    show: boolean;
    message: string;
    // not serializable
    confirm: () => void;
  };
  popups: Popup[];
  lastFetched: null | Date;
  expandedMachines: string[];
  activeTab: JobSummaryView;
  preferences: {
    pages: string[] | undefined;
  };
  files: {
    filesDataForFocusedJob: FileInfoForSelectedJob[];
    filesDetailsForFocusedJob: FileDetailsForSelectedJob[];
    downloadState: "initial" | "downloading" | "success" | "fail";
  };
  defaultRoute: Routes;
};

export type ManageMachineProvisioningState = {
  machines: ProvisionMachine[];
  selectedRegistrationId: GUID;
};

export const manageAssetsSections = ["licences", "locations"] as const;
export type MangeAssetsSection = (typeof manageAssetsSections)[number];
export type ManageAssetsState = {
  singleSelectedNode: string | null;
  multiSelectedNodes: string[];
  mode: TreeViewSelectionMode;
  openSections: MangeAssetsSection[];
  activeTab: string;
  confirmDeletion: boolean;
  deleteEntityType: "location" | "machine" | "none";
  refreshInProgress: boolean;
  machineConfigurations: Mappy<MachineConfiguration>;
  createMachine: boolean;
  formDataInvalidated: boolean;
  // cancelled state is to cancel test connection polling, does not show connection status when another machine is selected after clicking on Test Connection button
  connectionStatus:
    | "none"
    | "started"
    | "connected"
    | "failedToConnect"
    | "cancelled";
  connectionStatusBody: string;
  machineEdits?: Partial<Machine>;
  machineConfigEdits?: MachineConfigurationDetailsPatch;
  controllerResponseFromMachine: any;
};

export const manageNotificationsSections = [
  "machine alerts",
  "machine status",
  "job status",
  "job verdict",
] as const;
export type ManageNotificationsSection =
  (typeof manageNotificationsSections)[number];

export type ManageNotificationsState = {
  notificationSupported: boolean;
  notificationIsActivated: boolean;
  openSections: ManageNotificationsSection[];
  toggledLocationIds: string[];
  activeMachineAlerts: string[];
  activeMachineStatuses: string[];
  activeJobStatuses: string[];
  activeJobVerdicts: string[];
  activeLocations: string[];
  activeMachines: string[];
  notificationValues: MyNotification[];
  toggleManageDevices: boolean;
  selectAllMachinesAndLocations: boolean;
  headingControllers: string[];
};

export type JobPerformanceState = {
  jobTypes: JobType[];
  jobSummaries: JobPresentation[];
  // job type name -> performance
  jobTypePerformances: MappyAbsolute<JobPerformance>;
  // machine id -> job type name -> performance
  machinePerformances: MappyAbsolute<MappyAbsolute<JobPerformance>>;
  openJobs: string[];
  openMachines: string[];
  sortUnit: JobPerfSortUnit;
  sortColumn: JobPerfSortColumn;
  sortDirection: SortDirection;
  detailSortColumn:
    | JobPerfDetailSortColumnAdditive
    | JobPerfDetailSortColumnMetrology;
  detailSortDirection: SortDirection;
  machineJobAggregateSortColumn: JobPerfMachineJobAggregateSortColumn;
  machineJobAggregateSortDirection: SortDirection;
  work: JobPerfState;
  lastFetchedRange: null | [string, string];
};

export type ManageUsersState = {
  users: CentralUser[];
  selectedUserId: string;
  mode: ManageUpsertMode;
  confirmDeletion: boolean;
};

export type ManageClientsState = {
  clients: Client[];
  selectedClientId: string;
  selectedClientSecret: string | undefined;
  mode: ManageUpsertMode;
  confirmDeletion: boolean;
};
export type CurrentStatusState = {
  machineAlerts: Mappy<AlertPresentation[]>;
  viewMode: ViewMode;
  machineJobSummaries: Mappy<JobPresentation[]>;
  jobMeasurementCharacteristicsLoading: string[];
  jobMeasurementCharacteristics: Mappy<MeasurementCharacteristicPresentation[]>;
  machineCarouselData: Mappy<CarouselDataSet[]>;
  selectedMachineAlerts: string | undefined;
  cycleData: MappyAbsolute<{
    max: number;
    median: number;
    mean: number;
    min: number;
    numberOfJobs: number;
  }>;
  lastFetchedMachine: null | string;
  lastFetchedCarouselData: null | string;
  lastFetchedCycleTime: null | string;
  working: boolean;
};

// The name suggests that there is a "manage server" page, but there is not (yet).
export type ManageServerState = {
  serverLicenceState:
    | "unknown"
    | "licensed"
    | "unlicensed"
    | "LicenceServiceNotRunning"
    | "UnlicensedServiceRunning"
    | "ConnectionFailedToCentralServer";
  serverLicenceType: "unknown" | "Unlicensed" | "Cloud" | "On Prem" | "Edge";
  /** Empty string means a perpetual licence. Otherwise, this is a number (as a string) */
  serverLicenceDaysRemaining: string;
  licenceErrorMessage: string;
  licenceRetryErrorMessage: string;
  showFooter: boolean;
};
// #endregion

export type DataLoadingStates = "Fetching" | "Idle";
export const dataLoadingTypes = [
  "data",
  "focusedJobMoreDetails",
  "timeSeriesValues",
  "measurementSeriesValues",
] as const;
export type DataLoadingTypes = (typeof dataLoadingTypes)[number];

export const dataFetchedType = [
  "states",
  "jobs",
  "alerts",
  "timeSeriesValues",
  "measurementSeriesValues",
  "processActions",
  "unitHints",
] as const;
export type DataFetchedType = (typeof dataFetchedType)[number];

type JobMeta = {
  details: undefined | JobPresentation;
  relatedJobs: Mappy<JobPresentation>;
  moreDetails: undefined | Mappy<string | number | boolean>;
  relatedFocusedJob: {
    sortColumn: Date | undefined;
    sortDirection: "asc" | "desc";
  };
  // stores focused job reduced end date
  // being used in machine analysis page to unfocus focused job when reduced end date is before start date of daterange
  reducedEndDate: Date | undefined;
  // single job table sorting
  singleJobTable: {
    sortColumn: string;
    sortDirection: "asc" | "desc";
  };
};

export type State = {
  manageServer: ManageServerState;
  machinePerformance: MachinePerformanceState;
  manageUsers: ManageUsersState;
  manageMyAccount: {
    activeTab: string | undefined;
  };
  manageProvisioning: ManageMachineProvisioningState;
  manageAssets: ManageAssetsState;
  manageNotifications: ManageNotificationsState;
  manageClients: ManageClientsState;
  jobPerformance: JobPerformanceState;
  filter: FilterState;
  global: GlobalState;
  currentStatus: CurrentStatusState;
  processUpdates: ProcessUpdatesState;
  startupState: "started" | "complete";
  cacheLimit: number;
  view: Routes;
  loginView: ViewState;
  params: {
    start: string;
    end: string | null;
    focusedMachineId: null | string;
    focusedJobId: null | string;
    token: null | string;
    umrt: null | string;
  };
  focusedJob: JobMeta;
  latestJob: JobMeta;
  focusedMachine: {
    lastFetched: undefined | string;
    work: {
      data: DataLoadingStates;
      focusedJobMoreDetails: DataLoadingStates;
      timeSeriesValues: DataLoadingStates;
      measurementSeriesValues: DataLoadingStates;
    };
    /**
     * Important! Preserve order start ASC.
     */
    jobs: Mappy<JobPresentation>;
    /**
     * Important! Preserve order start ASC.
     */
    states: Mappy<MachineStatus>;
    /**
     * Important! Preserve order start ASC.
     */
    alerts: Mappy<AlertPresentation>;
    processActions: Mappy<ProcessAction>;
    hints: UnitDisplayHint[];
    /** true if the initial fetch of unit hints is in progress, used to suppress display of data with unknown units */
    awaitingHints: boolean;
    mainMeasurementDetail: string | undefined;
    measurementDetails: Mappy<MeasurementTypeDetail>;
    measurementValues: Mappy<
      DataRecord<MeasurementCharacteristicPresentation[]>
    >;
    measurementPlotType: MeasurementPlotType;
    timeSeriesLimits: Mappy<DataRecord<TimeSeriesLimit[]>>;
    timeSeriesDetails: Mappy<TimeSeriesTypeDetail>;
    timeSeriesValues: Mappy<DataRecord<TimeSeriesValueCompactValue[]>>;
    filters: {
      jobType: string[];
      alertName: string[];
    };
    selectedAlerts: string[];
    alertTypeParetoSelectedReason: AlertTypeReason | undefined;
    stateSummary: MachineStateSummary | undefined;
  };
  userViewPreference: {
    focusedOrLatestJobViewType: JobViewType;
    focusedOrLatestJobViewMeasurementType: FocusedOrLatestJobViewMeasurementType;
    machineAnalysisView:
      | MachineAnalysisMetrologyViewType
      | MachineAnalysisAdditiveViewType;
    activeSidebarSections: SidebarSection[];
    sidebarFilters: {
      jobs?: string;
      measurements?: string;
      timeseries?: string;
    };
  };
  machineSelector: {
    visibility: "collapsed" | "expanded";
    selected: undefined | string;
  };
  aboutPopup: {
    visibility: "collapsed" | "expanded";
  };
  visualisations: {
    machineAnalysis: {
      dimensions: {
        timeline: {
          width: number;
        };
        lineChart: {
          height: number;
        };
      };
      transforms: {
        timeline: {
          x: number;
          k: number;
        };
        yAxes: Mappy<{ y: number; k: number }>;
        yDeviation: { y: number; k: number };
      };
      seriesColours: Mappy<string>;
    };
  };
  previousLiveModeToken: KnownStartValues;
  username: string;
  authenticated: boolean;
  authenticating: boolean;
  systemPermissions: SystemPermissions[] | undefined;
  locationEntityPermissions: EntityPermission[];
  assignedLicences: Licence[];
  unassignedLicences: Licence[];
};
