/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { getContext } from "redux-saga/effects";
import {
  call,
  select,
  put,
  take,
  fork,
  cancel,
  takeLatest,
  all,
} from "typed-redux-saga";
import { CMSClientType } from "@/cms-api";
import {
  selectFocusedMachine,
  selectFocusedJobId,
  selectFocusedMachineJobs,
  selectIsRollingMode,
  selectLatestJob,
  selectFilteredJobs,
} from "@/store/selectors";
import { rootActions, RootState } from "..";
import { getType } from "typesafe-actions";
import { isMetrologyMachineType } from "@centralwebteam/narwhal";
import { MeasurementCharacteristicPresentation } from "@/presentation/MeasurementCharacteristic";
import { getGlobalConfigs } from "@/index";
import { JobPresentation, withMeasurementData } from "@/presentation/Job";
import {
  fetchFiles,
  fetchJobMetaData,
  fetchJobMoreDetails,
} from "@/store/sagas/shared";
import { data } from "./dataSaga";

export function* latestJobFlow() {
  yield* takeLatest(
    [getType(rootActions.machineAnalysis.mostRecentJobUpdated)],
    getLatestJobRelatedJobs
  );
  let task = yield* fork(fetchMostRecentJob);
  while (true) {
    yield* take([
      getType(rootActions.session.focusedJobIdUpdated),
      getType(rootActions.machineAnalysis.jobTimelineClicked),
      getType(rootActions.machineAnalysis.jobSummariesFetched),
      getType(rootActions.machineAnalysis.jobsSummariesDeltaFetched),
    ]);
    if (task) yield* cancel(task);
    task = yield* fork(fetchMostRecentJob);
  }
}

/**
 * Fetches the most recent job if a job isn't focused.
 */
function* fetchMostRecentJob() {
  try {
    const isRollingMode = yield* select(selectIsRollingMode);
    if (!isRollingMode) {
      return;
    }
    const machine = yield* select(selectFocusedMachine);
    // return early because we can't display a job without a machine.
    if (!machine) {
      return;
    } else if (!isMetrologyMachineType(machine.type)) {
      return;
    }
    const focusedJobId = yield* select(selectFocusedJobId);
    // return early because that will be displayed instead.
    if (focusedJobId) {
      return;
    }
    // use top to take the most recent job for the machine.
    const jobs = yield* select((store: RootState) =>
      Object.values(selectFocusedMachineJobs(store))
    );
    const lastJobInJobsCollection = jobs[jobs.length - 1];
    if (!lastJobInJobsCollection) {
      return;
    }

    yield* put(rootActions.machineAnalysis.unitDisplayHintsRequired());

    const measurements = yield* call(fetchMeasurementsForMachine, {
      machineId: lastJobInJobsCollection.machineId,
      from: lastJobInJobsCollection.start,
      to: lastJobInJobsCollection.end ?? new Date().toISOString(),
    });

    const moreDetails = yield* call(fetchJobMoreDetails, {
      jobId: lastJobInJobsCollection.id,
    });
    const filesInfoForSelectedJob: any = yield* call(fetchFiles, {
      jobId: lastJobInJobsCollection.id,
    });

    const metaData = yield* call(fetchJobMetaData, {
      machineId: lastJobInJobsCollection.machineId ?? machine?.id,
      startDate: lastJobInJobsCollection.start,
      endDate: lastJobInJobsCollection.end ?? new Date().toISOString(),
    });
    yield* put(
      rootActions.machineAnalysis.mostRecentJobUpdated({
        job: {
          ...lastJobInJobsCollection,
          type: "metrology",
          measurementCharacteristics: measurements.data,
        },
        moreDetails:
          metaData !== undefined
            ? { ...moreDetails, ...metaData }
            : moreDetails,
        filesInfoForSelectedJob: filesInfoForSelectedJob,
      })
    );
  } catch (error) {
    console.log(error);
  }
}

function* fetchMeasurementsForMachine({
  from,
  to,
  machineId,
}: {
  from: string;
  to: string;
  machineId: string;
}) {
  const config = yield* call(() => getGlobalConfigs());
  const client: CMSClientType = yield getContext("client");
  const measurements = yield* call(
    (query) => client.events.measurementCharacteristics.all(query).promise,
    { from, to, take: config.maxTake, machineId }
  );
  return { from, to, data: measurements };
}

/**
 * Flow to get latest job previous related jobs
 */
function* getLatestJobRelatedJobs() {
  try {
    const latestJob = yield* select(selectLatestJob);
    // If the current focused job is equal to the clicked one return early
    if (!latestJob) {
      return;
    }
    const client: CMSClientType = yield getContext("client");
    const { machineId, name: jobName, start } = latestJob;
    // select the previous selected jobs from existing jobs
    let jobsBefore: JobPresentation[] = [];
    const jobs = yield* select(selectFilteredJobs);
    const selectedMachineSimilarJobsAsLatest = jobs.filter(
      (j: JobPresentation) => j.name === jobName && j.machineId === machineId
    );

    if (selectedMachineSimilarJobsAsLatest) {
      jobsBefore = selectedMachineSimilarJobsAsLatest.filter(
        (j: any) => j.start <= latestJob.start
      );
      jobsBefore = jobsBefore ? jobsBefore.slice(-7) : [];
    }
    if (jobsBefore && jobsBefore.length < 7) {
      jobsBefore = yield* call(
        (str) =>
          client.jobs.summaryQuery(str).promise.then(
            (r) => r.reverse(),
            (r) => {
              console.warn(`Error fetching latest related jobs: ${r}`);
              return [];
            }
          ),
        `$filter=machineId eq ${machineId} and name eq '${encodeURIComponent(
          jobName
        )}' and start lt ${start}&$orderby=start desc &$top=6`
      );
    }

    const jobMeasurements = yield* all(
      jobsBefore.map((job: JobPresentation) =>
        call(fetchMeasurementsForMachine, {
          from: job.start,
          to: job.end || new Date().toISOString(),
          machineId: job.machineId,
        })
      )
    );

    const options = {
      from: latestJob.start,
      to: new Date().toISOString(),
      machineId: latestJob.machineId,
    };
    try {
      const unitHints = yield* call(data.fetchUnitHints, options);
      yield* put(
        rootActions.machineAnalysis.unitDisplayHintsDeltaFetched({
          ...options,
          hints: unitHints,
        })
      );
    } catch (ex) {
      yield* put(
        rootActions.machineAnalysis.unitDisplayHintsFetchingFinished()
      );
    }

    yield* all(
      jobMeasurements.map((measurements, index) => {
        // index matches
        return put(
          rootActions.machineAnalysis.relatedLatestJobFetched({
            job: withMeasurementData(
              jobsBefore[index],
              measurements.data as MeasurementCharacteristicPresentation[]
            ),
          })
        );
      })
    );
  } catch (error) {
    console.log(error);
  }
}
